import React from "react"
import { navigate } from "gatsby"
import Button from "elements/Button"

const CompleteProfileModal = () => {
  const handleClick = () => {
    navigate("/enrollment")
  }
  return (
    <div className="has-text-left">
      <p className="is-size-6 mb-1">
        You have to complete your profile before we can set you up with a mental
        health professional. Please click the button below to start.
      </p>
      <center>
        <Button className="is-primary" onClick={handleClick}>
          Complete Profile
        </Button>
      </center>
    </div>
  )
}

export default CompleteProfileModal
